import { Anchor, PageHeading } from 'components/atoms';
import { Head, PageLayout } from 'components/molecules';
import { getTranslations } from 'i18n';
import type { GetStaticProps, NextPage } from 'next';
import React from 'react';
import { useTranslation } from 'utils/hooks';

const Error404Page: NextPage = () => 
{
  const { t } = useTranslation(['common', 'errors']);

  return (
    <PageLayout textContentPage>

      <Head title={t('errors:404.meta.title')}>
        <meta name="robots" content="noindex" />
      </Head>
       
      <PageHeading title={t('errors:404.heading')} narrow />

      <p>{t('errors:404.description')}</p>

      <Anchor href="/">
        {t('common:actions.backToDashboard')}
      </Anchor>

    </PageLayout>
  );
};

export default Error404Page;

export const getStaticProps: GetStaticProps = async (ctx) =>
{
  const { locale } = ctx;
  const translations = await getTranslations(locale, 'errors');

  return { props: { ...translations } };
};